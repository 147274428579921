import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';
import Previewable from '../components/Previewable';
import '../acf/PageNavigationBlock.scss';
import './team-member.scss';

const AcfComponent = ({ item, location, wordpressUrl, role, teamMemberName, componentName }) => {
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      location={location}
      wordpressUrl={wordpressUrl}
      teamMemberName={teamMemberName}
      role={role}
      {...item}
    />
  );
};

export const TeamMemberNoLayoutTemplate = ({ title, content }) => {
  return (
    <section className="default-content">
      <div className="wrap">
        <h2 className="title" dangerouslySetInnerHTML={{__html: title}} />
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </section>
  )
}

const TeamMember = (props) => {
  const { data, location, pageContext } = props;
  const { wordpressWpTeamMember: teamMember, site } = data;
  if (!teamMember) return null;
  const { title, content, yoast, acf = {} } = teamMember;
  const { layout } = acf;
  const { title: siteTitle, wordpressUrl } = site.siteMetadata;
  const {
    next,
    previous
  } = pageContext;
  return (
    <Layout>
      <SEO
        title={yoast.metaTitle ? `${decodeEntities(yoast.metaTitle)}` : `${decodeEntities(title)} | ${siteTitle}`}
        desc={yoast.metaDescription}
      />
      {layout ? (
        <>
          {layout.map(item => {
            const layoutComponentName = item.name.replace('WordPressAcf_','');
            return (
              <AcfComponent
                key={item.id}
                componentName={layoutComponentName}
                teamMemberName={title}
                role={acf.role}
                item={item}
                location={location}
                wordpressUrl={wordpressUrl}
              />
            );
          })}
          {(previous || next) && (
            <section className="page-navigation-block simple-on-mobile">
              <div className="wrap">
                <div className={`left-link ${previous ? 'visible' : ''}`}>
                  {previous && (
                    <Link to={`/our-team/${previous.slug}/`} aria-label="Previous">
                      <span className="link-label">Previous</span>
                      {previous.title && <span className="headline" dangerouslySetInnerHTML={{__html: previous.title}} />}
                      {previous.acf.role && <span className="subtitle" dangerouslySetInnerHTML={{__html: previous.acf.role}} />}
                    </Link>
                  )}
                </div>
                <div className={`right-link ${next ? 'visible' : ''}`}>
                  {next && (
                    <Link to={`/our-team/${next.slug}/`} aria-label="Next">
                      <span className="link-label">Next</span>
                      {next.title && <span className="headline" dangerouslySetInnerHTML={{__html: next.title}} />}
                      {next.acf.role && <span className="subtitle" dangerouslySetInnerHTML={{__html: next.acf.role}} />}
                    </Link>
                  )}
                </div>
              </div>
            </section>
          )}
        </>
      ) :
        <TeamMemberNoLayoutTemplate title={title} content={content} />
      }
    </Layout>
  )
}

export default Previewable(TeamMember)

export const pageQuery = graphql`
  query TeamMemberById($id: String!) {
    site {
      siteMetadata {
        title
        wordpressUrl
        functionsUrl
      }
    }
    wordpressWpTeamMember(id: {glob: $id}) {
      title
      content
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      acf {
        role
        layout: layout_team_member {
          name: __typename,
          ... on WordPressAcf_TeamMemberIntro {
            introText
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            homeNumber
            mobileNumber
            email
            socialLinks {
              type
              url
            }
            bodyText
          }
          ... on WordPressAcf_TeamMemberGridBlock {
            orientation
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            bodyText
          }
          ... on WordPressAcf_TeamMemberAchievements {
            title
            Achievements {
              title
              description
            }
          }
        }
      }
    }
  }
`
